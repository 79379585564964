import { Grid, Stack } from '@mui/material';
import React from 'react';

const data = [
    { title: 'Have a startup mindset', description: 'We solve complex problems and do not hesitate to take risks that are well tested' },
    { title: 'Outcome over output', description: 'We care for measurable results' },
    { title: 'Focus on what’s needed', description: 'We build client’s needs than nice to haves' },
    { title: 'Act like business owner', description: 'We are committed, accountable and act like we own the client’s business' },
    { title: 'Be innovative & efficient', description: 'We strive to continuously learn and build great products.' },
    { title: 'Open & Transparent', description: 'We do the right thing with openness and transparency' },
];

export const Dna = () => {
    return (
        <Stack sx={{ color:"#fff", background:"#4f46e5"}} padding={{xs:"30px",md:"60px 40px"}} marginBottom="45px">
            <p style={{ fontSize:"32px",textAlign: 'center', fontWeight: 600}}>
               How we work
            </p>
            <Grid container spacing={2} sx={{padding:"0px 40px"}} marginTop="40px">
                {data.map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} sx={{textAlign:"center"}} marginBottom="40px">
                        <p style={{ fontSize:"20px",fontWeight:"500"}}>
                            {item.title}
                        </p>
                        <p style={{ fontSize:"16px",marginTop:"10px"}}>
                            {item.description}
                        </p>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};