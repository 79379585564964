// src/components/TalentSection.js
import React, { useState } from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';

const TalentSection = () => {


  const content = [
    {
      title: 'World Class Talent',
      description:
        'We hire seasoned engineers and leaders that have entrepreneurial spirit and a start-up mindset.',
    },
    {
      title: 'Accelerated Impact',
      description:
        'Our leaders & engineers focus on outcome through accelerated software development.',
    },
    {
      title: 'Experts that are partners',
      description:
        'We eye for talent & expertise that work with openness, transparency and deliver timely outcomes to enable a reliable partner in us.',
    },
  ];

  return (
    <Box sx={{ padding: '2rem', backgroundColor: '#f9fafb' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Side (Picture) */}
        <Grid item xs={12} md={6}>
        <Box component="img" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/iStock_81458689_MEDIUM_fgudig_otkwov.webp" alt="People working in an office"  />
        </Grid>

        {/* Right Side (Headings and Text) */}
        <Grid item xs={12} md={6}>
          {content.map((item, index) => (
            <Box
              key={item.title}
           
              sx={{
               
                padding: '1rem',
                backgroundColor: '#f9fafb',
                borderRadius: '8px',
                marginBottom: '1rem',
                transition: 'background-color 0.2s ease-in-out',
              }}
            >
              <Typography  sx={{fontSize:{xs:"22px",md:"26px"}, fontWeight: 600, borderBottom: '1px solid #ccc', paddingBottom: '0.5rem' }}>
                {item.title}
              </Typography>
           
                <Typography  sx={{ fontSize:{xs:"15px",md:"17px"},marginTop: '1rem' }}>
                  {item.description}
                </Typography>
            
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalentSection;
